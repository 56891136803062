.kiwi-inputtools-widget {
  bottom: 100%;
  overflow: hidden;
  position: absolute;
  height: 30px;
}
.kiwi-inputtools-widget-button {
  display: block;
  float: left;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background: var(--brand-default-bg);
  color: var(--brand-default-fg);
  box-sizing: border-box;
  border: 1px solid gray;
  margin: 0;
  font-size: 20px;
  font-family: serif;
  text-align: center;
  line-height: 30px;
}
.kiwi-inputtools-widget-bold {
  font-weight: bold;
}
.kiwi-inputtools-widget-italic {
  font-style: italic;
}
.kiwi-inputtools-widget-underline {
  text-decoration: underline;
}
.kiwi-inputtools-widget-reset {
  font-size: 23px;
  line-height: 30px;
  text-align: center;
}
